import React from 'react';
import './form.css'

function Form({weatherMethod}){
return(
    <form onSubmit={weatherMethod} >
        <input type='text' name='city' placeholder='Город' autoComplete="off"/>
        <button>Получить погоду</button>
        </form>
)}
export default Form;
import React, { useState } from 'react';
import Form from './form';
import Weather from './weather';

const API_KEY = 'a70ae5f03dee426899f5e1f7c86d8dd1';

function Sky(){
const init = {
    temp:undefined,
    city:undefined,
    sunrise:undefined,
    sunset:undefined,
    error:undefined
}
const [state, setState] = useState(init);
 const gettingWeather = async (e) => {
    e.preventDefault();
    const city = e.target.elements.city.value;
    const api_url = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${API_KEY}&units=metric`);
            const data = await api_url.json();
    if (data.name != null) {
        let ui = data.weather[0].icon;
            const url_icon = `http://openweathermap.org/img/wn/${ui}@4x.png`
              let sunsetInSec = data.sys.sunset;
              let date = new Date(sunsetInSec * 1000);
              let timeSunset = date.toLocaleTimeString();
              let sunriseInSec = data.sys.sunrise;
              let dateRise = new Date(sunriseInSec * 1000);
              let timeSunrise = dateRise.toLocaleTimeString();
              let pressure = data.main.pressure;
              let pressureInMmHg = Math.floor(pressure * 0.75006);

            setState(
                {
                    temp:data.main.temp,
                    city:data.name,
                    sunrise:timeSunrise,
                    sunset:timeSunset,
                    pressure:pressureInMmHg,
                    wind:data.wind.speed,
                    url_icon:url_icon,
                    error:undefined 
                }
            );
        }else{
            setState(
                {
                    temp:undefined,
                    city:undefined,
                    sunrise:undefined,
                    sunset:undefined,
                    pressure:undefined,
                    wind:undefined,
                    url_icon:undefined,
                    error:'Введите название города'
                }
            );
        }
        }
        

return(
    <div>
        <Form weatherMethod={gettingWeather} />
        <Weather
            temp={state.temp}
            sunrise={state.sunrise}
            sunset={state.sunset}
            city={state.city}
            error={state.error}
            pressure={state.pressure}
            wind={state.wind}
            url_icon={state.url_icon}

        />
    </div>
)

}

export default Sky;
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import './weather.css';

function Weather({temp,sunrise,sunset,city,error,pressure,wind,url_icon}){
const tempC = Math.round(temp);
return(  <div>{city &&

 <div>
    <hr />
          <Alert key={'info'} variant={'info'}>
          <p>Температура&#127777;: {tempC} &#8451;</p> 
          <img src={url_icon} alt="icon"/>
        </Alert>
        <Alert key={'primary'} variant={'primary'}>
        <p>Ветер &#128168;: {wind}  м/с</p>
        </Alert>
        <Alert key={'warning'} variant={'warning'}>
        <p>Давление<span className='ico_present'></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {pressure}  мм рт.ст.</p> 
        </Alert>
        <Alert key={'success'} variant={'success'}>
        <p className='time'>«Время — местное»</p>
        <p>Восход <span className='ico_sunrise'></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {sunrise}</p> 
        <p>Заход<span className='ico_sunset'></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {sunset}</p> 
        </Alert>
        <hr />
        </div>       
}
   <p>{error}</p>
  
</div>   
                    
)
}

export default Weather;
import './App.css';
import Sky from './component/sky';

function App() {
  return (
    <div className="App">
      <header className="App-header">
       <Sky />
      </header>
    </div>
  );
}

export default App;
